import React from 'react';
import config from '../../config/config'
import { getSession } from '../../authentication/cookie';
import { Modal as modal } from 'antd';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SessionExpire from '../../authentication/session-expire';

class BaseController extends React.Component {
  constructor(props) {
    super(props);
    this.baseUrl = config.apiUrl;
  }

  getToken = () => {
    let userId = getSession('UserId');
    let tokenKey = "token" + userId;
    return 'Bearer ' + getSession(tokenKey);
  }
  // http get
  async httpGet(url) {
    var relativeUrl = this.baseUrl + url;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'authorization': this.getToken() }
    };

    return await fetch(relativeUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          if (response.status === 401) {
            modal.error({
              content: <Modal isOpen={true} toggle={new SessionExpire().logOut}>
                <ModalHeader>Session expired</ModalHeader>
                <ModalBody>
                  Your session has been expired.
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-info" onClick={new SessionExpire().logOut}>Logout</button>
                </ModalFooter>
              </Modal>,
            });
          }
          else {
            throw new Error('Data fetching issue!');
          }
        }
      });

  }

  async httpPost(url, data) {
    var relativeUrl = this.baseUrl + url;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'authorization': this.getToken() },
      body: JSON.stringify(data)

    };

    return await fetch(relativeUrl, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {

          if (response.status === 401) {
            modal.error({
              content: <Modal isOpen={true} toggle={new SessionExpire().logOut}>
                <ModalHeader>Session expired</ModalHeader>
                <ModalBody>
                  Your session has been expired.
                </ModalBody>
                <ModalFooter>
                  <button className="btn btn-info" onClick={new SessionExpire().logOut}>Logout</button>
                </ModalFooter>
              </Modal>,
            });

          }
          else
          {
          throw new Error('Data submission issue!');
          }
        }
      });
  }



  //---------Multipart/FormData  -File upload-------------------


  async httpPostFormData(url, data) {

    var relativeUrl = this.baseUrl + url;
    const requestOptions = {
      method: 'POST',
      headers: { 'accept': 'application/json', 'authorization': this.getToken() },
      body: data

    };

    return await fetch(relativeUrl, requestOptions).then(response => {
      if (response.ok) {
        return response.json();
      } else {

        if (response.status === 401) {
          modal.error({
            content: <Modal isOpen={true} toggle={new SessionExpire().logOut}>
              <ModalHeader>Session expired</ModalHeader>
              <ModalBody>
                Your session has been expired.
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-info" onClick={new SessionExpire().logOut}>Logout</button>
              </ModalFooter>
            </Modal>,
          });
        }
        else {
          throw new Error('Data submission issue!');
        }  
      }
    });

  }

  // http downlaod file
  async httpGetDownload(path,type) {
    let url = "file/fileDownload";
    var relativeUrl = this.baseUrl + '/' + url + "?DocumentPath=" + path;
    if(type){
      relativeUrl=relativeUrl+"&Type="+type;
    }
  
   
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'accept': 'application/json', 'authorization': this.getToken() }
    };

    return await fetch(relativeUrl, requestOptions)
    //  .then(response => { 
    //   if (response.ok) {
    //           return response;
    //         } else {
    //           throw new Error('File is not available !!');
    //         }
    //   });
  }

}

export default BaseController

