import * as React from 'react';
import { AzureAD,AuthenticationState } from 'react-aad-msal';
import { basicReduxStore } from './reduxStore';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Login from './authentication/login';
// Import the authentication provider which holds the default settings
import { authProvider } from './authentication/auth-provider';
import './app.scss';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);  
  }

  render() {
    
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/default-layout'));

// Pages

    return (
      <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
        {({ authenticationState }) => {  
                 
          const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated           
          if(isUnauthenticated)
          {
            return (
                 <Login/>
            );
          }
          else
          {
          return (

              <HashRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                   <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                </Switch>
              </React.Suspense>
          </HashRouter>   

            );
          }  
        }}
      </AzureAD>
    );
  }
}
export default Dashboard;




