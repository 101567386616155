import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { getSession,setSession,resetSession } from '../authentication/cookie';
import { authProvider } from '../authentication/auth-provider';
import moment from 'moment';
import CommonUtilityController from '../master/controller/common-utility-controller';

class SessionExpire extends Component {

    constructor(props) {
        super(props);
        let tokenKey="token"+getSession('UserId');
        this.state = {
            loading:false,
            userId:getSession('UserId'),
            userName:getSession('UserName'),           
            tokenValue:getSession(tokenKey)
        }
       
    }

     /**
   * @method extendSession
   * This function will extend current user session.
   */
   extendSession =()=>
   {
       let{userId,userName,tokenValue}=this?.state;
    if(getSession('isExternalLogin'))
    { 
      let tokenKey="token"+userId;
      //set session for external user
      setSession(userId,userName,tokenKey,tokenValue,true)
    }
    else
    {
      window.location.reload();
    }
   
  }
 
  /**
   * @method logOut
   * This function will destroy current user session.
   */

    saveUserLogDetails=async (userLogs) => {   
    this.setState({loading: true, });   
    // await can not use outside any function
     await new CommonUtilityController().saveUserLogDetails(userLogs)    
                                      .then((result) => {        
                                        this.setState({loading: false, });                                        
                                        })    
                                    .catch(error =>{
                                          this.setState({loading: false, });                                       
                                    });
    
  }

  logOut =()=> 
    { 
    let tokenKey="token"+getSession("UserId");
    let userid=getSession("UserId");
    let token=getSession(tokenKey);
    if(getSession('isExternalLogin'))
    {
      resetSession();
      window.location.reload();
    }
    else
    {
      authProvider.logout();
      resetSession();
      window.location.reload();
    }

  
    let userLogs={
     UserLogId:0,
     UserId:userid,
     IPAddress:"",
     MACAddress:"",
     LogInStatus:2,
     LoginDate:moment().format('YYYY-MM-DD HH:mm:ss'),
     LogOutDate:moment().format('YYYY-MM-DD HH:mm:ss'),
     CreatedBy:userid,
     CreatedDate:moment().format('YYYY-MM-DD HH:mm:ss'),
     LockedBy:userid,
     LockedDate:moment().format('YYYY-MM-DD HH:mm:ss'),
     SecurityId:userid,
     TokenValue:token
  }
  
   this.saveUserLogDetails(userLogs);
  
  }

 
}
export default SessionExpire;