 
 //form layout
      const verticallayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
      }
    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },        
    }
    //submit button layout
    const tailLayout = {
      wrapperCol: { offset: 10, span: 16 },
    };
     //submit button horizontal layout
     const tailLargeLayout = {
      wrapperCol: { offset: 5, span: 16 },
    };

    
//form item layout
    const itemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 31 },
       
    } 
    const rowSpan = 24;
    const colSpan = 12;

  //alignment  
  const labelAlign = "left";
 //validation
 
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
      number: '${label} is not a validate number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };
// Modal pop up setting

const modalStyle = {
  width: 1050,
  style:{top:55},
} 

const mapStyle = {
  width: 900,  
  style:{top:55},
  padding:0,  

} 
const modalLoginStyle = {
  width: 400,  
  style:{top:100},
  align:'center'
} 
const modalSmallStyle = {
  width: 600,
  style:{top:100},
} 

const modalDbStyle = {
  width: 900,
  style:{top:55},
} 
const selectSearch = {
showSearch:true,
optionFilterProp:"children",
filterOption:(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0  
}
// work Package Length
const workPackageLength = 35;
// contract Length
const contractLength = 35;
 // activity Length
const activityLength = 35;
// Remarks Length
const remarksLength = 35;
  export {
      layout,
      tailLayout,
      modalLoginStyle,
      tailLargeLayout,
      verticallayout,
      itemLayout,
      labelAlign,
      rowSpan,
      colSpan,
      validateMessages,
      modalStyle,
      modalSmallStyle,
      contractLength,
      remarksLength,
      activityLength,
      modalDbStyle,
      workPackageLength,
      selectSearch,
      mapStyle
  }
  