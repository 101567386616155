import { createStore,applyMiddleware  } from 'redux';
import rootReducer from './redux/reducers'
import thunk from "redux-thunk"
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const basicReduxStore = createStore(
  rootReducer,
  applyMiddleware(thunk) 
  // Enable the Redux DevTools extension if available
  /// See more: https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfiblj
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
