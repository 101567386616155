const LoginState = {
    UserId: 0,
    UserName: "",
    AdUserName:"",
    EmployeeNo:"",
    EmployeeName:"",
    Department:"",
    Designation:"",
    EmailId: "",    
    CurrentDate:null ,
    LockedBy:null, 
    LockedDate:null,
    SecurityId:null,  
    IsExternalUser:false,
    IsLoggedin:false,
    UserLocation:''
  };

export default LoginState