import env from "react-dotenv";
export function setCookie(cname, cvalue, minuts) {
    let d = new Date();
    d.setTime(d.getTime() + (minuts * 60 * 1000)); // (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }
  
  export function getSession(cname) {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) ===' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  
    return '';
  }
  
  export function checkSession() {    
    let userId = getSession('UserId');
    let tokenKey="token"+userId;
    let user = getSession(tokenKey);
    if (user !== '') {
      return user;
    } else {
      return null;
    }
  }
  
  
  export function resetSession() {
    let userId = getSession('UserId');
    let tokenKey="token"+userId;
    setCookie(tokenKey, "", 0);
    setCookie('UserId', '', 0);
    setCookie('UserName', '', 0);
    setCookie('isExternalLogin', '', 0);
    setCookie('IsRights', '', 0);
    setCookie('EmployeeName', '', 0);
    setCookie('msal.a7da6db6-7f4e-433f-8ddb-95dc561f3a15.idtoken', '', 0);
    setCookie('msal.a7da6db6-7f4e-433f-8ddb-95dc561f3a15.client.info', '', 0);
    setCookie('msal.idtoken', '', 0);
    setCookie('msal.client.info', '', 0);
    sessionStorage.clear();
  }


  
  export function resetInternalSession() {  
    setCookie('msal.69d0a1d2-9fca-4ef5-a0bc-7b24e925b59d.idtoken', "", 0);
    setCookie('msal.idtoken', '', 0);
    setCookie('msal.client.info', '', 0);
    setCookie('msal.69d0a1d2-9fca-4ef5-a0bc-7b24e925b59d.client.info', '', 0);
  }

  export function setSession(UserId,UserName,tokenKey,tokenValue,isExternalLogin) {
    setCookie('UserId', UserId, env.SESSION_TIMEOUT) 
    setCookie(tokenKey, tokenValue, env.SESSION_TIMEOUT);
    setCookie('UserName', UserName,env.SESSION_TIMEOUT);
    setCookie('isExternalLogin', isExternalLogin, env.SESSION_TIMEOUT);
  }
  export function setNewSession(key,value) {
    setCookie(key, value, env.SESSION_TIMEOUT) ; 
  }