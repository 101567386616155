import * as React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './app.scss';

class ExternalDashboard extends React.Component {
  constructor(props) {
    super(props);
  
  }
  
  render() {
    
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Containers
const ExternalLayout = React.lazy(() => import('./containers/default-layout/external-layout'));
// Pages

    return (
              <HashRouter>
                 <React.Suspense fallback={loading()}>
                    <Switch>                 
                      <Route path="/" name="Home" render={props => <ExternalLayout {...props}/>} />
                    </Switch>
                 </React.Suspense>
            </HashRouter> 
            );
         
  }
}
export default ExternalDashboard;




