import React, { Component } from 'react';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { basicReduxStore } from './reduxStore';
import { authProvider } from './authentication/auth-provider';
import { getSession } from './authentication/cookie';
import PrivateRoute from './private-route';
import Login from './authentication/login';
import Dashboard from './dashboard';
import ExternalDashboard from './external-dashboard';
import './styles/app.css';

class App extends Component {
  constructor(props) {
    super(props);   
    this.state = {
      isExternalLogin:false
    } 
   
  }
  componentDidMount() {
    let isExternalLogin= getSession("isExternalLogin");   
    this.setState({isExternalLogin:isExternalLogin});   
  }
  render() {
  let {isExternalLogin}=this.state;
    if(isExternalLogin)  
       {
        return (
            <div className="App" > 
              <React.Fragment> 
                 <PrivateRoute path='/' component={ExternalDashboard} />      
              </React.Fragment>
            </div>
        )
       } 
       else
       {  
          return (
            <div className="App" >  
            <AzureAD provider={authProvider} reduxStore={basicReduxStore}>           
                {({ accountInfo,logout, authenticationState, error }) => {
                return (
                    <React.Fragment>            
                      
                      {authenticationState === AuthenticationState.Unauthenticated && (
                      <Login/>
                      )}
                      {authenticationState === AuthenticationState.Authenticated && (
                        
                        <Dashboard/>
                      )}
                    </React.Fragment>
                  );
                }}
              </AzureAD>      
            </div>        
          );
  }
  }
}


export default App;











