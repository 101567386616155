import * as React from 'react';
import moment from 'moment';
import { AzureAD, LoginType, AuthenticationState } from 'react-aad-msal';
import { basicReduxStore } from '../reduxStore';
import { setSession,setNewSession } from './cookie';
import {  UserOutlined,LockOutlined } from '@ant-design/icons';
import LoginState from './loginState';
import PrivateRoute from '../private-route';
import ExternalDashboard from '../external-dashboard';
// Import the authentication provider which holds the default settings
import { authProvider } from './auth-provider';
import CommonUtilityController from '../master/controller/common-utility-controller';
import {Space, Card, Button, Row, Col,Modal,Tooltip,Form, Input,Spin } from 'antd';
import {  modalLoginStyle,layout,labelAlign,validateMessages,rowSpan,tailLayout, } from '../helper/form-helper'
/*********Core UI************** */
import '../../src/app.scss'
import config from '../config/config'
const publicIp = require('public-ip');
//Azure Login
class Login extends React.Component {  
  
  constructor(props) {
    super(props);
    this.formRef = React.createRef();  
    this.state = {
      users : {
        userid: 0,
        username: '',
        adusername: '',
        password: '',
        emailid: '',
        isapprover: false,
        approverid: 0,
        approveremployeeno: '',
        approveremployeename:'',
        approveremailid:'',
        loginmsg: '',
        islogin: false,
        visible:false,
        loading:false,
        isExternalLogin:false,
        UserName:'',
        UserId:'',
        otp:'',
        tokenValue:'',
        loginEnabled:false
      }
    };
   
    
    // Change the login type to execute in a Popup
   const options = authProvider.getProviderOptions();
   options.loginType = LoginType.Popup;
   authProvider.setProviderOptions(options);
   window.scrollTo({top: 0, behavior: 'smooth'});
  }

  async componentDidMount() {  
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({isExternalLogin: false});   
    await this.verifyDbConnection();
    setTimeout(() => {
      this.setState({loading:false});      
    }, 5100);
  }

  async verifyDbConnection() {
    this.setState({loading: true });
     setNewSession("emailUrl","");
     await new CommonUtilityController().verifyDbConnection()    
                                      .then((result) => {                                         
                                         this.setState({loginEnabled: true,loading:false});
                                         if(result!=null)
                                         {                                         
                                          let url=window.location.href;
                                          let path=url.split("#");
                                          if(path.length>1){                                          
                                          if(path[1]!='/'||path[1]!='/dashboard'){                                           
                                          setNewSession("emailUrl",path[1]);
                                          }
                                        }
                                         }
                                      })    
                                      .catch(error =>{
                                            this.setState({loginEnabled:false });
                                            Modal.error({         
                                              content:<p>DataBase/API Connection Issue !!</p>,
                                            });
                                      });
      
    }

  userNameChange=(e)=> {   
    this.setState({UserName: ''});
    if(e.target.value!="")
    {     
    this.setState({UserName: e.target.value});
    }
  }
//external login
  onLogin =async (values) => {  
    var {otp,UserId,tokenValue}=this.state;
    var ipAddress="";
   // ipAddress=await publicIp.v4();
    if(values.Password==otp)
    {
      let tokenKey="token"+UserId;
     // let tokenValue="xyz12345"+UserId+moment().format('YYYY-MM-DD HH:mm:ss');
      let userLogs={
        UserLogId:0,
        UserId:UserId,
        IPAddress:ipAddress,
        MACAddress:ipAddress,
        LogInStatus:1,
        LoginDate:moment().format('YYYY-MM-DD HH:mm:ss'),
        LogOutDate:moment().format('YYYY-MM-DD HH:mm:ss'),
        CreatedBy:UserId,
        CreatedDate:moment().format('YYYY-MM-DD HH:mm:ss'),
        LockedBy:UserId,
        LockedDate:moment().format('YYYY-MM-DD HH:mm:ss'),
        SecurityId:UserId,
        TokenValue:tokenValue
     }
      this.saveUserLogDetails(userLogs);
      //set session for external user
      setSession(UserId,values.UserName,tokenKey,tokenValue,true);
      setNewSession("EmployeeName",LoginState.EmployeeName)
      this.setState({isExternalLogin: true});
    }
    else
    {   
      Modal.error({         
        content:<p>Invalid OTP !!</p>,
      });
    }
   
  };
  
  externalLogin = () => {      
    this.setState({visible: true,loading:false});
  }


  sendOTP= () => {
    let {UserName}=this.state;    
    if(UserName=="" ||UserName==undefined)
    {
      Modal.error({         
        content:<p>User Name is required !!</p>,
      });
      return false;
    } 
    this.getUserInfo(UserName);
  }

 async getUserInfo(userName) {
    this.setState({loading: true,tokenValue:'' });
    let  regData={'UserName':userName,'UserType':'External'};

     await new CommonUtilityController().getUserInfo(regData)    
                                      .then((result) => {        
                                          this.setState({loading: false, }); 
                                                                              
                                                    if(result.length>0)
                                                    {          
                                                      LoginState.UserId =result[0].UserId;
                                                      LoginState.UserName =result[0].UserName;
                                                      LoginState.AdUserName = result[0].AdUserName;
                                                      LoginState.EmployeeNo = result[0].EmployeeNo;
                                                      LoginState.EmployeeName =result[0].EmployeeName;
                                                      LoginState.Department = result[0].Department;
                                                      LoginState.Designation = result[0].Designation;
                                                      LoginState.EmailId = result[0].EmailId;  
                                                      LoginState.CurrentDate =moment().format('YYYY-MM-DD HH:mm:ss');
                                                      LoginState.LockedDate =moment().format('YYYY-MM-DD HH:mm:ss');                                                      
                                                      LoginState.LockedBy =result[0].UserId;
                                                      LoginState.SecurityId =result[0].UserId;    
                                                      this.setState({otp:result[0].OTP,UserId:result[0].UserId,tokenValue:result[0].TokenValue})    
                                                                                                                                                     
                                                      Modal.success({         
                                                        content:<p>OTP has been sent to {LoginState.EmailId}</p>,
                                                      });
                                                    } 
                                                    else
                                                    {
                                                      Modal.error({         
                                                        content:<p>Unauthorized user!!</p>,
                                                      });
                                                    }
                                        
                                        })    
                                    .catch(error =>{
                                          this.setState({loading: false, });
                                          Modal.error({         
                                            content:<p>Unauthorized user!!</p>,
                                          });
                                    });
    
  }
  
  toggleModal = () => {
    this.setState({UserName: ''});
      this.setState({
          visible: !this.state.visible
      });
  }
  async saveUserLogDetails(userLogs) {
    this.setState({loading: true, });   
    // await can not use outside any function
     await new CommonUtilityController().saveUserLogDetails(userLogs)    
                                      .then((result) => {        
                                        this.setState({loading: false, });                                        
                                        })    
                                    .catch(error =>{
                                          this.setState({loading: false, });                                         
                                    });
    
  }
  render() {
      let {loginEnabled,visible,loginData,isExternalLogin,loading}=this.state;
      
      if(isExternalLogin)  
      {
       return (
           <div className="App" >            
             <React.Fragment>  
             <PrivateRoute path='/' component={ExternalDashboard} />           
             </React.Fragment>
           </div>
       )
      } 
      else
      {  
        
    return (
      <div> 
      <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
        {({ login, logout, authenticationState }) => {
          const isInProgress = authenticationState === AuthenticationState.InProgress;        
          const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;
         
          if (isUnauthenticated || isInProgress ) {
         
            return (  
     <div>     
    <div style={{marginTop:100}} align="center">
    <Space align="center"> 
     <Row gutter={rowSpan} >
      <Col span={rowSpan} >
          <Card>
            <h2><p style={{marginTop:30}}>e-PMC</p></h2>
            <div><img alt='' style={{width: 100, height: 30, }} src="/img/logo.png" /></div>
            <strong>Sign In to your account</strong>
            <br/>
            <br/>
            <br/>
            <div className="space-align-container">
              <div className="space-align-block">
                <Space align="center"> 
                <Tooltip placement="top" title="Systra Users" > 
                  <Button title="systra users" type="primary"  onClick={login} disabled={isInProgress ||!loginEnabled} className="px-4">Login</Button>  
                  </Tooltip>
                  <Tooltip placement="top" title="Non Systra Users" > 
                    <Button  type="primary" disabled={!loginEnabled} className="px-4" onClick={this.externalLogin}>External</Button>  
                  </Tooltip> 
                </Space>
              </div>
              </div>
          </Card>         
        </Col>
      </Row> 
      </Space>    
    </div>
    <Modal title="External Login"   {...modalLoginStyle} footer={false} visible={visible} onCancel={this.toggleModal} destroyOnClose={true}>
    <Spin spinning={loading}>
       <Form {...layout} ref={this.formRef} labelAlign={labelAlign} size="small" name="external_login" onFinish={this.onLogin} validateMessages={validateMessages}  initialValues={loginData}  > 
          <Row gutter={rowSpan}>
                  <Col span={rowSpan} >                 
                      <Form.Item  name="UserName"  label="User Name" rules={[{ required: true, message: 'Please input your Username!' }]} >
                        <Input onChange={this.userNameChange} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                      </Form.Item>                      
                   </Col>                  
              </Row> 
              <Row gutter={rowSpan}>
                    <Col span={rowSpan} >  
                    <Form.Item  name="Password" label="OTP" rules={[{ required: true, message: 'Please input your OTP!' }]}    >
                      <Input  prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="OTP" />
                    </Form.Item>                                 
                  </Col>                               
              </Row>   
              <Row gutter={rowSpan} >
                  <Col span={rowSpan}> 
                  <Form.Item  {...tailLayout} >
                   <Button  size="small" type="primary" htmlType="submit"   >Login</Button>
                   <Button  size="small" style={{marginLeft:8}}  type="primary" htmlType="button" onClick={this.sendOTP}   >Send OTP</Button>
                   <Button size="small" style={{marginLeft:8}} onClick={this.toggleModal}> Close </Button>  
                   </Form.Item>
                   </Col>       
               </Row>
         </Form>
     </Spin>     
    </Modal>
   </div>    


   
            );
          }
        }}
      </AzureAD>
      </div>  
    );
  }
}
}
export default Login;

